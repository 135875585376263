import { lazy } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { defaultLanguage, LanguageGuard } from '../containers/Language';
import { UserAuthGuard, useUserAuth } from '../containers/UserAuth';
import { Role } from '../types';

const DealerPage = lazy(() => import('./DealerPage'));
const DashboardPage = lazy(() => import('./DealerPage/pages/DashboardPage'));
const SearchPage = lazy(() => import('./DealerPage/pages/SearchPage'));
const ControlCenterPage = lazy(
  () => import('./DealerPage/pages/ControlCenterPage')
);
const SFTPStatusPage = lazy(
  () => import('./DealerPage/pages/SFTPStatus/page')
);
const PairingPage = lazy(
  () => import('./DealerPage/pages/PairingPage/PairingPage')
);
const VehicleDetailsPage = lazy(
  () => import('./DealerPage/pages/VehicleDetails/page')
);
const PriceboardDetailsPage = lazy(
  () => import('./DealerPage/pages/PriceboardDetailsPage/page')
);
const PairingDetailsPage = lazy(
  () => import('./DealerPage/pages/PairingDetailsPage/PairingDetailsPage')
);
const AdminPage = lazy(() => import('./DealerPage/pages/AdminPage/Page'));
const ScanPage = lazy(() => import('./DealerPage/pages/ScanPage'));
const NotFoundPage = lazy(() => import('./NotFoundPage'));

// @TODO - add error handling https://reactrouter.com/en/main/start/tutorial#handling-not-found-errors
// @TODO - move routes to some constant

export const RootRoutes = () => {
  const { roles } = useUserAuth();

  // const IndexRoute = shouldShowTenant(userData?.access_token) ? DashboardPage : SearchPage;
  const isSuperAdmin = roles?.includes(Role.SUPER_ADMIN);

  const showSftpStatusPage = useFeatureFlagEnabled('sftp-status-page');

  return (
    <Routes>
      <Route path="/:lang" element={<LanguageGuard />}>
        <Route element={<UserAuthGuard />}>
          <Route element={<DealerPage />}>
            <Route index element={<DashboardPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="search" element={<SearchPage />} />
            <Route path="pair" element={<PairingPage />} />
            <Route path="vehicle-details/:vehicleId" element={<VehicleDetailsPage />} />
            <Route path="priceboard-details/:priceboardId" element={<PriceboardDetailsPage />} />
            <Route
              path="pair-details/:vehicleId"
              element={<PairingDetailsPage />}
            />
            <Route path="control-center" element={<ControlCenterPage />} />
            {isSuperAdmin && [
              <Route path="admin" element={<AdminPage />} />,
              <Route path="scan" element={<ScanPage />} />
            ]}
            {showSftpStatusPage && (
              <Route path="sftp-status" element={<SFTPStatusPage />} />
            )}
          </Route>
        </Route>
      </Route>
      <Route
        path="/"
        element={
          <Navigate
            to={`/${window.location.href.replace(
              window.location.origin,
              defaultLanguage
            )}`}
          />
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
